export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const wholeNumbersRegex = /^[0-9]+$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?])(?=.*[a-zA-Z\d!@#$%^&*()\-_=+{};:,<.>/?]).{8,}$/;

export const wordsFollowedByCommaOrSpaceRegex = /[^\s,]+/g;

export function validateUsername(username: string) {
  const regex = /^[a-zA-Z0-9_]{3,}$/;
  return regex.test(username);
}
export function validateUDID(udid: string) {
  const regex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  return regex.test(udid);
}

export function validateEmail(email: string) {
  return emailRegex.test(email);
}
export function validatePassword(password: string) {
  return passwordRegex.test(password);
}
export function lengthRegex(length: number): RegExp {
  return new RegExp(`^.{${length}}$`);
}
export function minLengthRegex(length: number): RegExp {
  return new RegExp(`^.{${length},}$`);
}
export function maxLengthRegex(length: number): RegExp {
  return new RegExp(`^.{0,${length}}$`);
}
export function alphaNumbericUnderscoreRegex(length: number): RegExp {
  return new RegExp(`^[a-zA-Z0-9_]{${length},}$`);
}
