import { cn } from "@/lib/utils";
import React, { ButtonHTMLAttributes } from "react";
import SpanLoading from "./SpanLoading";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
};
export default function ButtonWithLoading({
  children,
  isLoading,
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      className={cn(rest.className, `${isLoading ? "btn-disabled" : ""}`)}
    >
      {isLoading && <SpanLoading />}
      {children}
    </button>
  );
}
