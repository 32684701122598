import toast, { Renderable, ValueOrFunction } from "react-hot-toast";

export function toastPromise(
  promise: Promise<unknown>,
  loadingText: string = "Loading...",
  message: string = "Done!",
  error: ValueOrFunction<Renderable, any> = "Whoops! something went wrong...."
) {
  return toast.promise(promise, {
    loading: loadingText,
    success: <b>{message}</b>,
    error,
  });
}
export function toastInfo(
  message: string = "confirmation email resent",
  icon: Renderable | undefined = undefined
) {
  toast.success(message, {
    icon,
  });
}
export function updateLoadingToastTestWithToastID(
  text: string,
  toastID: string
) {
  toast.loading(text, {
    id: toastID,
  });
}

export function toastLoading(
  message: string = "Loading...",
  icon: Renderable | undefined = undefined
) {
  return toast.loading(message, {
    icon,
  });
}
export function dismissToastWithID(toastID: string) {
  toast.dismiss(toastID);
}
export function toastError(error: string = "Whoops! something went wrong....") {
  // toast.custom((t) => (
  //   <div
  //     className={`${
  //       t.visible ? "animate-enter" : "animate-leave"
  //     } alert alert-info pointer-events-auto flex w-full max-w-md rounded-lg  shadow-lg ring-1 ring-black ring-opacity-5`}
  //   >
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       className="h-6 w-6 shrink-0 stroke-current"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth="2"
  //         d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
  //       ></path>
  //     </svg>
  //     <span>{error}</span>
  //     {/* <div className="flex border-l border-gray-200">
  //       <button
  //         onClick={() => toast.dismiss(t.id)}
  //         className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
  //       >
  //         Close
  //       </button>
  //     </div> */}
  //   </div>
  // ));
  toast.error(error);
}
